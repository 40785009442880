body, #root {
    min-height: 100vh;
}

h1,h2,h3,h4,h5,h6,h7 {
    margin-top: 20px;
    margin-bottom: 20px;
}
.modal-90w {
    width: 95%;
    max-width: none!important;
}

.caret {
    display: inline-block;
    width: 0;
    height: 0;
    margin-left: 2px;
    vertical-align: middle;
    border-top: 4px dashed;
    border-top: 4px solid\9;
    border-right: 4px solid transparent;
    border-left: 4px solid transparent;
}

.dropup .caret, .navbar-fixed-bottom .dropdown .caret {
    content: "";
    border-top: 0;
    border-bottom: 4px dashed;
    border-bottom: 4px solid\9;
}

.calendar {
    height: 95vh !important;
}

.loginBox {
    box-shadow: 4px 4px 10px #CBCBCB;
    padding: 40px;
    background: #fff;
    height: auto !important;
    position: absolute;
    top: 200px;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
}

.compact-form .form-group {
    margin-bottom: 0.5rem;
}

    .product-select .rbt-menu {
        width: 500px !important;
    }

    .basic-typeahead-single-customer input {
        background: #e3e3e3;
    }

.home-cols img {
    width: 175px;
}
.home-cols {
    text-align: center;
    font-weight: bolder;
    font-family: arial, serif;
}

.home-cols a {
    text-decoration: none;
    color: #000;
}

.btn-primary {
    color: #000;
    background-color: #FBB900;
    border-color: #FBB900;
}
