.fc-button-primary {
    background-color: #f6A604 !important;
    color: #fff !important;
}

td.fc-resource .fc-datagrid-cell-cushion {
    background-color: #F6A604 !important;
    color: #fff !important;
}

.fc-event-time {
    display: none !important;
}

.fc .fc-scrollgrid-liquid {
    height: 95% !important;
}

.resourceImage {
    margin-left: -40px;
    margin-right: 20px;
}